/* eslint-disable jsx-a11y/alt-text */
"use client";
import React, { useEffect, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";
import MarkdownIt from 'markdown-it';
import ReactPlayer from 'react-player';
import { BiLoaderCircle } from "react-icons/bi"
import "./index.css"
import VideoBox from "./VideoLink";
import { type } from "os";
import VideoPlayer from "./VideoPlayer";
import CustomPromptModal from "./CustomPromptModal";
import logo from "./systemIcon.svg"
import mainLogo from "./MetabolicLogo.svg"
import user from "./User.svg"
import Loader from "./Loader.gif"
import json5 from 'json5';
import { async } from "q";

// import Lottie from 'lottie-react';
// import animationData from 'https://lottie.host/f4118416-6a7b-4577-a78c-84a41a91b199/Ic6IFkg3ti.json';

interface Message {
  id: string;
  role: string;
  content: string;
  video?:
  {
    url: string,
    title: string,
    start: string,
    end: string
  }[]
  | []
}

export interface CustomDetailsInterface {
  showPopUp: boolean;
  prompt: string;
  gpt_version: string
}

const md = new MarkdownIt();

const MarkdownRenderer: React.FC<{ markdownContent: string }> = ({ markdownContent }) => {
  const htmlContent = md.render(markdownContent);

  return (

    <div className="prose no-tailwind " dangerouslySetInnerHTML={{ __html: htmlContent }} />

  );
};

export default function Home() {

  const [messages, setMessages] = useState<Message[]>([{
    "id": "cZVNpQ",
    "role": "assistant",
    "content": "Hi, I’m FxMed AI, a practitioner’s assistant trained on the methodologies of Dr. Bryan Walsh. I’m here to help you navigate the scientific world of functional and nutritional medicine. How can I help?",
    "video": []
  }])
  const [input, setInput] = useState<string>("")
  const [parentURL, setParentURL] = useState<string | undefined>('');
  const [loader, setLoader] = useState<boolean>(false)
  const [openPlayer, setOpenPlayer] = useState<boolean | string[]>(false)
  const [cookies, setCookies] = useState<any>()
  const [customDetails, setCustomDetails] = useState<CustomDetailsInterface>({
    showPopUp: false,
    prompt: "",
    gpt_version: "gpt-3.5-turbo-1106"
  })
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [firstLoader, setFirstLoader] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const testToken =async (secretToken:any)=>{
    const request = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/data`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token' : secretToken
      },
    })
    const response = await request.json()
    return response
  }
  const removeUrl = () => {
    const url = new URL(window.location.href);
    url.pathname = '/';
    url.search = '';
    window.history.replaceState({}, document.title, url.href);
  }
  const checkToken = async () => {
    setFirstLoader(true)
    try {
      
      const url = window.location.href;
      const queryParams = new URLSearchParams(window.location.search);
      const secretParam = queryParams.get('secret');

      const cookies = document.cookie;

      // If you have a specific cookie you want to retrieve
      const xToken = document.cookie.replace(/(?:(?:^|.*;\s*)x-token\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (secretParam) {
        
        const checkToken = await testToken(secretParam)
        if(checkToken?.success){
          const expirationDate = new Date();
          expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);
          document.cookie = `x-token=${secretParam}; expires=${expirationDate.toUTCString()}; path=/`;
        }else{
          setShowPopUp(true)
        }
      }else if(xToken){

        const checkToken = await testToken(xToken)
        if(checkToken?.success){
        }else{
          setShowPopUp(true)
        }
        
      }else{
        setShowPopUp(true)
      }
      removeUrl()
    } catch (error) {
      setShowPopUp(true)
      removeUrl()
      console.error('Error checking token:', error);
    }finally {
      setFirstLoader(false); // Set loader to false after API calls are done
    }
  };
  useEffect(() => {
    checkToken()
  },[])

  function handleInputChange(e: any) {
    setInput(e.target.value)
  }

  async function handleSubmit(e: any) {
    e.preventDefault()
    if(/^\s*$/.test(input)){
      return 
    }
    setMessages([...messages, { id: generateRandomString(6), role: "user", content: input }])
    setInput("")
    await sendApiRequest(input)
  }
  const messageEndRef = useRef<HTMLInputElement>(null);

  const remove_last_element=(str:string)=>{
    const lastIndex =str.lastIndexOf('.');

    // Check if "." is found in the string
    if (lastIndex !== -1) {
      // Get the substring up to the last occurrence of "."
      const result = str.substring(0, lastIndex + 1);
      return result
    } else {
      return str
    }


  }


  function replaceSingleQuotesWithDoubleQuotes(inputString:string) {
    return inputString.replace(/'/g, '"');
  }

  async function sendApiRequest(input: string) {
    const id = generateRandomString(6)
    setLoader(true)
    var tmpPromptResponse = '';
    try {
      let newMessage = { id: id, role: "assistant", content: "" };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const cookies = document.cookie;

      // If you have a specific cookie you want to retrieve
      const xToken = document.cookie.replace(/(?:(?:^|.*;\s*)x-token\s*=\s*([^;]*).*$)|^.*$/, '$1');
      const headers = {
        'Content-Type': 'application/json',
        'x-token': `${xToken}`
      };
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/data`, {
        method: 'POST',
        headers: headers,

        // credentials: 'include',
        body: JSON.stringify({ question: input, domain: "http://127.0.0.1:5500", history: messages, customDetails: customDetails })
      });
      if(response.headers.get('Content-Type') === "application/json"){
        const data = await response.json()
        if(data.hasOwnProperty('message')){
          let newMessage = { id: id, role: "assistant", content: ``};
          setMessages((prevMessages) => {
            const newArray: Message[] = prevMessages.map(message => {
              if (message.id === newMessage.id) {
                message.content = "Something went wrong , Ask again "
              }
              return message
            })
            return newArray
          });
          setLoader(false)
          return
        }
        setShowPopUp(true)

        return
      }
      let decoder = new TextDecoderStream();
      // try()
      if (!response.body) return;
      
      const reader = response.body.pipeThrough(decoder).getReader();

      while (true) {
        var {value, done} = await reader.read();
        
        if (done) {
          break;
        } else {
          tmpPromptResponse += value;
          if(!tmpPromptResponse.includes('Refer ##')){
            setMessages((prevMessages) => {
              const newArray: Message[] = prevMessages.map(message => {
                if (message.id === newMessage.id) {
                  message.content = tmpPromptResponse
                  message.video = []
                }
                return message
              })
              return newArray
            });
          }
        }
      }
      

      let dataIndex = tmpPromptResponse.indexOf("***array***");
      let extractedData = tmpPromptResponse.substring(dataIndex + "***array***".length);
      let trimmedData = extractedData.trim();

      
      let dataArray;
      try {
        // dataArray = JSON.parse(`${trimmedData}`);
        dataArray=json5.parse(trimmedData);
      } catch (error) {
        dataArray = [];
      }

    
     



      if(tmpPromptResponse.includes("Refer ##")){
        let pattern = /Refer ##(.*?)data: \*\*\*array\*\*\*/s;
        let match = tmpPromptResponse.match(pattern);
        let result = match ? match[1] : null;
  
        const resultArray =result !==null? generate_array(result, dataArray):[]
        setMessages((prevMessages) => {
          const newArray: Message[] = prevMessages.map(message => {
            if (message.id === newMessage.id) {
              
              message.video = resultArray
              message.content=remove_last_element(message.content)
            }
            return message
          })
          return newArray
        })

      }
      else{
        let dataIndex = tmpPromptResponse.indexOf("data: ***array***");
        let trimmedResponse = tmpPromptResponse.substring(0, dataIndex).trim()
        setMessages((prevMessages) => {
          const newArray: Message[] = prevMessages.map(message => {
            if (message.id === newMessage.id) {
              
              message.content=trimmedResponse
            }
            return message
          })
          return newArray
        })
      }


      setLoader(false)
    } catch (e) {

      let newMessage = { id: id, role: "assistant", content: `${e}`};
      setMessages((prevMessages) => {
        const newArray: Message[] = prevMessages.map(message => {
          if (message.id === newMessage.id) {
            message.content = "Something went wrong , Ask again "
          }
          return message
        })
        return newArray
      });
      setLoader(false)
    }
  }


  function generate_array(content: any, arr: any) {
    const resultArray: any[] = [];

    for (const item of arr) {
      // Convert both the content and title to lowercase for case-insensitive comparison
      if (content.toLowerCase().includes(item.title.toLowerCase().trim())) {
        resultArray.push(item);
      }
    }
    return resultArray;
  }



  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function generateRandomString(length: number): string {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomString += charset.charAt(randomIndex);
    }

    return randomString;
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    window.addEventListener('message', function (event) {
      try {
        if (event.origin === process.env.REACT_APP_PARENT_URL) {
          setParentURL(event.origin);
          setCookies(event.data)
        }
      } catch (e) {
        console.log(e, "error");
      }
    });
  }, []);

  function redirect(object: any) {
    setOpenPlayer([object.video_url, object.start])
  }
  if(firstLoader){
    return(
      <div className="h-screen w-screen bg-gray-100 flex items-center">
        <div className="w-full h-full flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <img src={mainLogo} className="w-[32px] h-full p-1" alt="SystemIcon.png" />
            <span className="text-xl ml-2 mr-2">Metabolic Fitness</span>
          </div>
            <div>
            <svg height="40" width="65" className="loader">
              <circle className="dot" cx="15" cy="20" r="8" style={{ fill: '#F24617' }} />
              <circle className="dot" cx="35" cy="20" r="8" style={{ fill: '#F24617' }} />
              <circle className="dot" cx="55" cy="20" r="8" style={{ fill: '#F24617' }} />
            </svg>
            </div>
            
            </div>
          {/* <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
          </div> */}
          
        </div>
      </div>
      
    )
  }
  if(showPopUp){
    return(
      <div className="h-screen w-screen bg-gray-100 flex items-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="max-w-md">
            <div className="flex items-center text-5xl font-dark font-bold">
              <img src={mainLogo} className="w-[40px] h-full p-1" alt="SystemIcon.png" />
              <p className="ml-4 text-3xl">Login Again</p>
            </div>
            <div className="ml-4">
              <p
                className="text-2xl font-light leading-normal mt-4"
              >Sorry you cant login. </p>
              <p className="mb-8">But rest assured, you can still log in, simply return to the Metabolic page, log in, and click the button in the bottom right corner.</p>
              <a href="https://metabolic-fitness.getlearnworlds.com/">
                <button className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">
                  Go to Metabolic Fitness</button>
              </a>
            </div>

          </div>
          <div className="max-w-lg">
          </div>

        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="relative">
        {customDetails.showPopUp && <CustomPromptModal setCustomDetails={setCustomDetails} customDetails={customDetails} />}
        {
          <div className="min-h-screen min-w-[99vw] bg-[#ffffff]">
            {/* {customDetails.showPopUp === false && <button onClick={() => setCustomDetails((prev) => { return ({ ...prev, showPopUp: true }) })} className="fixed top-0 right-6  flex float-right mx-2 my-3 px-3 min-h-[44px] py-1 items-center gap-3 text-white text-sm rounded-md border font-medium border-white/20 gizmo:min-h-0  h-11 gizmo:h-10 gizmo:rounded-lg gizmo:border-[rgba(0,0,0,0.1)] bg-[#525252]  flex-grow overflow-hidden z-[99] cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon-sm shrink-0" height="1em" width="1em">
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              <span className="truncate">Add Prompt</span>
            </button>
            } */}

            <div className="w-full text-primaryText border-b-[1px] border-primaryBorder flex items-center px-[30px] py-[21.8px]">
              <img src={mainLogo} className="w-[56px] h-full p-1" alt="SystemIcon.png" />
              <h1 className="font-semibold   md:text-[30px] text-base px-5 text-center align-middle w-auto max-w-[800px] ">
                Ask a Question
              </h1>
            </div>

            {messages.length !== 0 && (
              <div className=" pt-5 pb-24 space-y-5 w-[100%] mx-auto relative">
                {messages.map((message) => (
                  <div key={message.id} className="w-full">
                    {message.role === "user" ? (
                      <div className="lg:px-[16rem] md:px-16 px-3 py-3  flex  gap-x-2 justify-end">
                        <p className="  items-center md:text-[23px] text-base text-primaryBackground  w-fit max-w-[100%] pl-[30px] pr-[25px] py-[25px] rounded-xl bg-primaryText">
                          {message.content}
                        </p>
                        <div className="bg-transparent w-[70px] rounded-lg mx-3">
                          <img src={user} className="w-full h-fit mt-4 " alt="SystemIcon.png" />
                        </div>


                      </div>
                    ) : (
                      <div className="lg:px-[16rem] md:px-16 px-3 py-3 flex gap-x-2 justify-start">
                        <div className=" w-[70px] rounded-lg mr-3">
                          <img src={logo} className="w-full h-fit mt-4  " alt="SystemIcon.png" />
                        </div>

                        <div className="items-center md:text-[23px] text-base text-[#1e4b74] bg-secondaryBackground w-fit max-w-[100%] pl-[30px] pr-[25px] py-[25px] rounded-xl" >
                          <MarkdownRenderer markdownContent={message.content} />
                          {message.content.length === 0 && loader && <svg height="40" width="65" className="loader">
  <circle className="dot" cx="15" cy="20" r="8" style={{ fill: '#F24617' }} />
  <circle className="dot" cx="35" cy="20" r="8" style={{ fill: '#F24617' }} />
  <circle className="dot" cx="55" cy="20" r="8" style={{ fill: '#F24617' }} />
</svg>}



                          {message.content.length != 0 && <div className="flex mt-5  w-full flex-row flex-wrap">
                            {message.video?.map((e: any, _i: number) => {
                              if (e.video_url) {
                                return <VideoBox key={_i} redirect={redirect} object={e} />
                              }

                            })}
                          </div>}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div ref={messageEndRef}></div>

            <form
              onSubmit={handleSubmit}
              className="p-5 fixed bottom-0 left-0 lg:w-[75%] w-full mx-auto right-0 "
            >
              <div className="relative flex items-center">
                <Textarea
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent the default Enter key behavior
                      handleSubmit(e);
                    }
                  }}
                  tabIndex={0}
                  required
                  rows={1}
                  value={input}
                  onChange={handleInputChange}
                  autoFocus
                  placeholder="Send message..."
                  spellCheck={false}
                  className={`w-full focus:outline-none shadow-xl placeholder:text-secondaryText   border border-[1px] border-primaryText    text-secondaryText p-5 pr-16 rounded-xl md:text-[23px] text-base bg-primaryBackground   max-h-[300px] ${loader && 'opacity-50 cursor-not-allowed'}`}
                  style={{ resize: 'none' }} // Prevent textarea from being resizable
                  disabled={loader}
                />
                <button
                  type="submit"
                  className={`absolute bg-[#C90707] p-2 rounded-lg right-0 mr-5 ${loader && 'opacity-50 cursor-not-allowed'}`}
                  disabled={loader}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5 text-white"
                  >
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>}
      </div>
      {
        typeof (openPlayer) == "object" && openPlayer[0].includes("http") && <VideoPlayer openPlayer={openPlayer} setOpenPlayer={setOpenPlayer} />
      }
    </React.Fragment>
  );
}