// Test.js

import React from 'react';

const Test = () => {
  return (
    <div>
      <h2>Server is up and running </h2>
    </div>
  );
}

export default Test;
