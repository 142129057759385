import React from 'react';
import videoPlay from "./play-g.svg"
const VideoBox = ({ object , redirect}: any) => {
 
    return (
          <div onClick={()=>redirect(object)} className='flex items-center w-[45%] m-1.5 px-3 py-3.5 rounded-lg border border-[#9c9898]' >
            <button title={object.title} className="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl text-left text-[#1e4b74]  md:whitespace-normal" >
                <div className="flex w-full gap-2 items-center justify-center">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex flex-row items-center overflow-hidden">
                            <div className="flex flex-row flex-nowrap items-center justify-start truncate">
                                <img src={videoPlay} className="w-10 pr-2" alt="SystemIcon.png" />
                                <p>{object.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        </div>
        
    );
};

export default VideoBox;
