import React, { useState, useRef, useEffect } from 'react';
import { CustomDetailsInterface } from "./App";
import { AiOutlineClose } from 'react-icons/ai';

interface Props {
  setCustomDetails: React.Dispatch<React.SetStateAction<CustomDetailsInterface>>;
  customDetails: CustomDetailsInterface;
}

const CustomPromptModal: React.FC<Props> = ({ setCustomDetails, customDetails }) => {
  const versions: string[] = [ "gpt-4-1106-preview", "gpt-3.5-turbo-1106" , "gpt-3.5-turbo-16k",  "gpt-3.5-turbo"];

  const [localPrompt, setLocalPrompt] = useState<string>(customDetails.prompt);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<string | null>(customDetails.gpt_version || null);

  const dropdownRef = useRef<HTMLDivElement>(null);
 
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleVersionSelect = () => {
    setCustomDetails({
      ...customDetails,
      prompt: localPrompt,
      gpt_version: selectedVersion as string,
      showPopUp: false,
    });
    setIsOpen(false);
  };

  const closeVideo = () => {
    setCustomDetails({ ...customDetails, showPopUp: false });
  }

  function setVersion (version : string) {
    setSelectedVersion(version)
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <div className='fixed top-0 z-[99] left-0 w-screen h-screen flex justify-center items-center bg-[#00000088]'>
        <div ref={dropdownRef} className='lg:w-1/3 lg:h-fit min-h-fit py-3  bg-[#f3f3f3] text-base text-[#1e4b74] rounded-lg relative'>
          <header className='text-xl py-5  flex text-left font-medium border-b-[1px] border-gray-600 px-8'>
            Custom instructions
          </header>
          <AiOutlineClose
            className="text-[#1e4b74] absolute top-3 right-3 cursor-pointer z-10 text-2xl"
            onClick={closeVideo}
          />
          <main className='flex flex-col px-4 '>
            <label className='p-3 leading-4 text-left mt-4 justify-center items-center flex-col'>
              <span className=''> Write your prompt in the below input field</span>
              <textarea
                className="w-full mt-3 resize-none rounded p-4 placeholder:text-gray-300 dark:bg-[#dbdbdb] border-gray-100 focus:border-none focus:outline-none"
                rows={7}
                spellCheck={false}
                value={localPrompt}
                onChange={(e) => setLocalPrompt(e.target.value)}
              />
            </label>
            <div className="flex flex-col p-3">
              <label htmlFor="version" className="mb-1 text-[#1e4b74]">Select ChatGPT Version:</label>
              <div className="relative inline-block">
                <div
                  className="p-2 bg-gray-500/10 text-[#1e4b74] rounded-md mt-3 cursor-pointer"
                  onClick={toggleDropdown}
                >
                  {selectedVersion || 'Select Version'}
                  <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'} ml-2`}></i>
                </div>
                {isOpen && (
                  <div className="absolute w-full bg-[#f3f3f3] border border-gray-300/10 rounded-md shadow-md">
                    {versions.map((version) => (
                      <div
                        key={version}
                        className={`p-2 cursor-pointer hover:bg-[#b3b3b3]`}
                        onClick={() => setVersion(version)}
                      >
                        {version==="gpt-4-1106-preview" ? "gpt-4-1106-preview ( Recommended )": version}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                setCustomDetails({
                  ...customDetails,
                  prompt: localPrompt,
                  gpt_version: selectedVersion || 'gpt-3.5-turbo',
                  showPopUp: false,
                });
                setIsOpen(false);
              }}
              className="flex abcd abcd justify-center items-center mx-2 border rounded-md hover:bg-[#525252ec] h-11 bg-[#525252] text-white flex-grow overflow-hidden"
            >
              <span className="truncate text-center">Save</span>
            </button>
          </main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomPromptModal;
