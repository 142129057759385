import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { AiOutlineClose } from 'react-icons/ai';

interface VideoPlayerProps {
  openPlayer: string[] | boolean;
  setOpenPlayer: React.Dispatch<React.SetStateAction<string[] | boolean>>;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ openPlayer, setOpenPlayer }) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [initialSeekDone, setInitialSeekDone] = useState(false);

  useEffect(() => {
    if (!initialSeekDone && isVideoPlaying) {
      let num;
      if (typeof openPlayer === "object") {
        const str = openPlayer[1];
        num = parseInt(str, 10);
      }
      if (playerRef.current && typeof num === "number") {
        playerRef.current.seekTo(num);
        setInitialSeekDone(true);
      }
    }
  }, [isVideoPlaying, initialSeekDone, openPlayer]);

  function closeVideo() {
    setOpenPlayer(false);
  }
  const playerConfig = {
    file: {
      forceVideo: true,
      attributes: {
        controlsList: 'nodownload',
      },
    },
  };
  return (
    <div className="fixed top-0 left-0 z-[100] w-screen h-screen bg-black flex justify-center items-center">
     <div className='absolute top-3 right-3 cursor-pointer z-10 text-4xl bg-black rounded-full p-2'> <AiOutlineClose
        className="text-white border-1 border-black text-2xl"
        onClick={closeVideo}
      />
</div>
      {openPlayer && typeof openPlayer === 'object' ? (
        <div >
          <ReactPlayer
            ref={playerRef}
            url={openPlayer[0] as string}
            controls
            width="100%"
            height="auto"
            playing={true}
            onPlay={() => setIsVideoPlaying(true)}
            config={playerConfig}
          />
        </div>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
